import {
  Box,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textContent: {
      color: "black",
      padding: 8,
      fontSize: 28,
      textAlign: "center",
      [theme.breakpoints.down("xs")]: {
        fontSize: "20px",
      },
    },
  })
);

export const InfoBar = (): JSX.Element => {
  const classes = useStyles();

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      bgcolor={"#E9D502"}
    >
      <Typography className={classes.textContent}>
        Uniquement sur place. Les pizzas sont disponibles à emporter du lundi au
        jeudi.
      </Typography>
    </Box>
  );
};
